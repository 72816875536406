import {createSlice} from "@reduxjs/toolkit";

const initialStatisticsState = {
  listLoading: false,
  actionsLoading: false,
  session: null,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: initialStatisticsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    
    // sessionStatistics
    sessionStatisticsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.session = data;
    },

    assignmentStatisticsFetched: (state, action) => {
      const { data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.assignment = data;
    },
  }
});
