import { createSlice } from "@reduxjs/toolkit";

const initialVcState = {
  listLoading: false,
  actionsLoading: false,
  countServer: 0,
  serverList: null,
  sessionList: [],
  countSession: 0,
  serverForEdit: undefined,
  settingConfigs: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const vcSlice = createSlice({
  name: "vc",
  initialState: initialVcState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
    },
    serversFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.serverList = entities;
      state.countServer = totalCount;
    },
    sessionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.sessionList = entities;
      state.countSession = totalCount;
    },


    // getVcById
    serverForEditFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.serverForEdit = data || {};
      state.error = null;
    },
    // findVcs
    
    // createVc
    vcCreated: (state, action) => {
      state.ewactionsLoading = false;
      state.error = null;
      state.vcList.push(action.payload.vc);
    },
    // updateVc
    vcUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vcList = state.vcList.map((entity) => {
        if (entity.id === action.payload.vc.id) {
          return action.payload.vc;
        }
        return entity;
      });
    },
    // deleteVc
    vcDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vcList = state.vcList.filter((el) => el.id !== action.payload.id);
    },
    // deleteVcs
    vcsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.vcList = state.vcList.filter((el) => !action.payload.ids.includes(el.id));
    },
    // vcsUpdateState
    vcsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.vcList = state.vcList.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    settingConfigsFetched: (state, action) => {
      const { data } = action.payload.data;
      state.listLoading = false;
      state.settingConfigs = data || [];
      state.error = null;
    },
  },
});
