import { createSlice } from "@reduxjs/toolkit";

const initialApplicationState = {
  listLoading: false,
  actionsLoading: false,
  countApplication: 0,
  applicationList: null,
  applicationForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const applicationSlice = createSlice({
  name: "application",
  initialState: initialApplicationState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      }
    },
    applicationsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;

      state.applicationList = entities;
      state.countApplication = totalCount;
    },
    applicationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.applicationList.push(action.payload.application);
    },
    applicationFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.applicationForEdit = data || {};
      state.error = null;
    },
  },
});
