import { createSlice } from "@reduxjs/toolkit";

const initialSessionsState = {
  listLoading: false,
  actionsLoading: false,
  countSession: 0,
  sessions: [],
  importingSessions: null,
  totalImportingCount: 0,
  sessionForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialSessionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSessionById
    sessionFetched: (state, action) => {
      state.actionsLoading = false;
      state.sessionForEdit = action.payload.sessionForEdit;
      state.error = null;
    },
    // findSessions
    sessionsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.sessions = entities;
      state.countSession = totalCount;
    },
    // createSession
    sessionCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.sessions.push(action.payload.session);
    },
    // updateSession
    sessionUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sessions = state.sessions.map((entity) => {
        if (entity.id === action.payload.session.id) {
          return action.payload.session;
        }
        return entity;
      });
    },
    // deleteSession
    sessionDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sessions = state.sessions.filter(
        (el) => el.id !== action.payload.id
      );
    },
    // deleteSessions
    sessionsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sessions = state.sessions.filter(
        (el) => !action.payload.ids.includes(el.id)
      );
    },
    // sessionsUpdateState
    sessionsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.sessions = state.sessions.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },

    // syncrhonize
    sessionSynchronized: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.sessions = state.sessions.filter(
        (el) => el.id !== action.payload.id
      );
    },

    sessionsFileUploaded: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.importingSessions = entities;
      state.totalImportingCount = totalCount;
    },

    sessionsImported: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.importingSessions = null;
      state.totalImportingCount = 0;
    },
  },
});
