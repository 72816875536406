import React from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider, useIntl } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/de";
import "@formatjs/intl-relativetimeformat/dist/locale-data/es";
import "@formatjs/intl-relativetimeformat/dist/locale-data/fr";
import "@formatjs/intl-relativetimeformat/dist/locale-data/ja";
import "@formatjs/intl-relativetimeformat/dist/locale-data/zh";
import "@formatjs/intl-relativetimeformat/dist/locale-data/vi";

import enAllMessages from "./messages/en";
import viAllMessages from "./messages/vi";

// VI
import viAccount from "app/modules/Account/i18n/vi.json";
import viApplication from "app/modules/Application/i18n/vi.json";
import viUser from "app/modules/User/i18n/vi.json";
// EN
import enAccount from "app/modules/Account/i18n/en.json";
import enApplication from "app/modules/Application/i18n/en.json";
import enUser from "app/modules/User/i18n/en.json";
import viErrors from "./errors/vi";

const viMessages = () => {
  const messages = {
    ...viAllMessages,
    ...viAccount,
    ...viApplication,
    ...viUser,
    ...require("app/modules/Template/i18n/vi.json"),
    ...require("app/modules/Vc/i18n/vi.json"),
  };
  return messages;
};
const enMessages = () => {
  const messages = {
    ...enAllMessages,
    ...enAccount,
    ...enApplication,
    ...enUser,
  };
  return messages;
};

const allMessages = {
  en: enMessages(),
  vi: viMessages(),
};

const allErrorMessages = {
  en: enMessages(),
  vi: viErrors,
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = { ...allMessages[locale], ...allErrorMessages[locale] };

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}


export const useTranslate = () => {
  const {formatMessage} = useIntl();


  const t = (id) => {
    return formatMessage({id: id});
  }


  return ({t})
}
