/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { Icon } from "@material-ui/core";
import { toAbsoluteUrl, checkIsActive } from "app/common/_helpers";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "menu_dashboard" })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Account */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: "menu_account" })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Account Pages */}
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/account", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/account">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Stamp.svg")} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "menu_account" })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* Tool */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: "menu_tool" })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        

        {/*end::1 Level*/}

        {/* Account */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">VC</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li className={`menu-item ${getMenuItemActive("/vc/server/list", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/vc/server/list">
            <span className="svg-icon menu-icon">
            </span>
            <span className="menu-text">Server</span>
          </NavLink>
        </li>
        <li className={`menu-item ${getMenuItemActive("/vc/session/list", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/vc/session/list">
            <span className="svg-icon menu-icon">
            </span>
            <span className="menu-text">Session</span>
          </NavLink>
        </li>
        {/* end:: section */}


        {/* Account */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">{intl.formatMessage({ id: "menu_setting" })}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
       
        {/* end:: section */}

        

        {/* User Pages */}
        {/*begin::1 Level*/}
        <li className={`menu-item ${getMenuItemActive("/user/list", false)}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/user/list">
            <span className="svg-icon menu-icon">
              <Icon className={"fa fa-user-graduate menu-icon"} />
            </span>
            <span className="menu-text">{intl.formatMessage({ id: "menu_user" })}</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
