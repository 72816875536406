import { createSlice } from "@reduxjs/toolkit";

const initialUserState = {
  listLoading: false,
  actionsLoading: false,
  countUser: 0,
  userList: [], //null
  userForEdit: undefined,
  settingConfigs: null,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.importLoading = false;
      }
    },

    usersFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.listLoading = false;
      state.userList = data.data ? data.data : [];
      if (data.objects) {
        state.countUser = data.objects.total;
      } else if (data.total) {
        state.countUser = data.total;
      } else {
        state.countUser = 0;
      }
    },
    userFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.userForEdit = data || {};
      state.error = null;
    },
  },
});
