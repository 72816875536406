import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "app/layout";

const AccountPage = lazy(() => import("./modules/Account/pages/AccountPage"));
const SessionPage = lazy(() => import("./modules/Session/pages/SessionPage"));

const AnalyticPage = lazy(() => import("./modules/Analytic/pages/AnalyticPage"));
const UserPage = lazy(() => import("./modules/User/pages/UserPage"));
const ApplicationPage = lazy(() => import("./modules/Application/pages/ApplicationPage"));
const TemplatePage = lazy(() => import("./modules/Template/pages/TemplatePage"));

const routes = [
  {
    path: "/vc",
    component: lazy(() => import("./modules/Vc/pages/VcPage"))
  }
]

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        <Route path="/account" component={AccountPage} />
        <Route path="/session" component={SessionPage} />
        <Route path="/dashboard" component={AnalyticPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/application" component={ApplicationPage} />
        <Route path="/template" component={TemplatePage} />
        {
          routes.map(route => {
            return <Route key={route.path} path={route.path} component={route.component} />
          })
        }

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
