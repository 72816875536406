import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../redux/auth";
import { statisticsSlice } from "../app/modules/Analytic/redux/analyticSlice";
import { accountsSlice } from "app/modules/Account/redux/accountSlice";
import { sessionSlice } from "app/modules/Session/redux/sessionSlice";
import { userSlice } from "app/modules/User/redux/userSlice";
import { applicationSlice } from "app/modules/Application/redux/applicationSlice";
import { templateSlice } from "app/modules/Template/stores/templateSlice";
import { vcSlice } from "app/modules/Vc/redux/vcSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  accounts: accountsSlice.reducer,
  session: sessionSlice.reducer,
  user: userSlice.reducer,

  statistics: statisticsSlice.reducer,
  application: applicationSlice.reducer,
  template: templateSlice.reducer,
  vc: vcSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
