import React, {createContext} from "react";
import {useMemo} from "react";
import {useContext} from "react";

const LMS_CONFIG_KEY = process.env.REACT_APP_LMS_CONFIG_KEY || "LmsConfig";
const initialState = {
    selectedLms: "uni"
  };

function getConfig() {
  const ls = localStorage.getItem(LMS_CONFIG_KEY);
  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (er) {
      console.error(er);
    }
  }
  return initialState;
}

// Side effect
export function setLms(lms) {
  localStorage.setItem(LMS_CONFIG_KEY, JSON.stringify({ selectedLms: lms }));
  window.location.reload();
}

const lmsContext = createContext();

export function useLms() {
  return useContext(lmsContext).selectedLms;
}

export function getLms() {
    return getConfig().selectedLms;
  }

export function withLms(Component) {
  class WithLms extends React.Component {
    static displayName = `WithLms(${Component.displayName || Component.name})`;

    static contextType = lmsContext;

    render() {
      return <Component {...this.props} menu={this.context} />;
    }
  }

  return WithLms;
}

export const LmsConsumer = lmsContext.Consumer;

export function LmsProvider({ children }) {
  const lms = useMemo(getConfig, []);

  return <lmsContext.Provider value={lms}>{children}</lmsContext.Provider>;
}
